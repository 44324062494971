import React, { createContext, useContext, useMemo, useEffect } from 'react';
import get from 'lodash.get';
import jwtDecode from 'jwt-decode';

// eslint-disable-next-line import/no-cycle
import auth, { SESSION_TOKEN_LOCAL_STORAGE_KEY } from '../../modules/auth/auth';
import { isBrowser } from '../../config/config';

import patientsService from '../services/patients.service';
import { useLocalStorageWatcher, localStorageGet } from '../../hooks/useLocalStorageSync';
import useDataApiEffect from '../../hooks/useDataApiEffect';
import useDataApi from '../../hooks/useDataApi';
import { useCookieWatcher } from '../../helpers/cookie';

const AuthContext = createContext();

/**
 * This provider handles all of our search state. Wrapping a new provider will create a new scoped-state.
 * Meaning you can have multiple individual searches on a page. Useful for inpage results vs overlay results.
 */
export const AuthProvider = ({ ...props }) => {
    /** We have a long time cookie that is set to show if a user has authed before. */
    const [authedBefore] = useCookieWatcher('authed_before', true);

    const authSession = useLocalStorageWatcher(SESSION_TOKEN_LOCAL_STORAGE_KEY);
    const decodedAccessToken = useMemo(() => (authSession ? jwtDecode(authSession.access_token) : null), [authSession]);

    // Gets a fresh logged in state. Useful for absolute values
    const checkLoggedIn = () => !!get(localStorageGet(SESSION_TOKEN_LOCAL_STORAGE_KEY), 'access_token');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isLoggedIn = useMemo(checkLoggedIn, [authSession]); // Is a stateful logged in state, good for things reacting to things.

    const clearLoggedOutStorage = () => auth.clearLocalStorage();

    /**
     * Autoload the patient in when logged in variable changes & is true
     */
    const [
        { data: patientData, error: patientError, isLoading: loadingPatient },
        refreshPatientData, // Commas are needed, do not remove.
        ,
        updatePatientState,
    ] = useDataApiEffect(isLoggedIn ? patientsService.getAuthenticatedPatient : () => {}, [isLoggedIn]);

    const patient = get(patientData, 'data');
    const patientUuid = get(decodedAccessToken, 'patient_uuid') || get(patient, 'uuid');
    const patientName = get(decodedAccessToken, 'patient_name') || get(patient, 'identity.firstname');

    const [
        { isLoading: isUpdatingPatient, isSuccess: updatePatientSuccess, error: updatePatientError },
        updatePatient,
        resetUpdatePatient,
    ] = useDataApi((uuid, patchData) => patientsService.updatePatient(uuid, patchData));

    /**
     * Updates a patients details.
     * @param {string} action - CHANGE_PASSWORD || UPDATE_WEIGHT_HEIGHT || UPDATE_GP || UPDATE_DETAILS.
     * @param {object} details - The patient details to update.
     * @param {string} uuid - Manually supply the UUID of the patient to update (Uses authed patient UUID if not supplied).
     */
    const updatePatientDetails = async (action, details, uuid = '') => {
        const patchData = {
            action,
            ...details,
        };

        // Use the supplied UUID if available, else, use the authed patient UUID.
        const patientToUpdate = uuid || patientUuid;
        if (!patientToUpdate) {
            return null;
        }

        const response = await updatePatient(patientToUpdate, patchData);

        if (response) {
            if (action === 'UPDATE_DETAILS') {
                auth.refreshToken(); // If name is updated then this refreshes name in token.
            }

            await refreshPatientData();
        }

        return response;
    };

    /**
     * Logs a patient out with API, clears local storage and cookie data.
     */
    const logout = async (redirect = null) => {
        if (isBrowser()) {
            window.dispatchEvent(new CustomEvent('loggingOut'));
        }

        await auth.logout();

        if (isBrowser()) {
            const redirectUrl = redirect || window.location.href;

            if (redirectUrl.includes('/order-confirmed')) {
                window.location.href = '/';
                return;
            }

            window.location.href = redirectUrl;
        }
    };

    /**
     * If both patient uuid from the /me endpoing and uuid from the token exist but do not match then log us out.
     */
    useEffect(() => {
        if (
            get(decodedAccessToken, 'patient_uuid') &&
            get(patient, 'uuid') &&
            get(decodedAccessToken, 'patient_uuid') !== get(patient, 'uuid')
        ) {
            refreshPatientData();
        }
    }, [patient, decodedAccessToken]);

    /**
     * Runs function when logged out user closes browser tab, leaves site, or closes window.
     */
    useEffect(() => {
        if (isLoggedIn || !isBrowser()) {
            return () => {};
        }

        if (!isLoggedIn || isBrowser()) {
            updatePatientState(null);
        }

        window.addEventListener('beforeunload', clearLoggedOutStorage);
        return () => window.removeEventListener('beforeunload', clearLoggedOutStorage);
    }, [isLoggedIn]);

    // TODO: We need to add a use memo to this to prevent re-renders (contextValueRefactor, Ticket: 862jfuy9w).
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const value = {
        patient,
        patientUuid,
        patientName,
        patientError,

        refreshPatientData,
        loadingPatient,

        isUpdatingPatient,
        updatePatientSuccess,
        updatePatientError,
        resetUpdatePatient,
        updatePatientDetails,

        authedBefore,
        authSession,

        isLoggedIn,
        checkLoggedIn,

        logout,

        storybook: false,
    };

    return <AuthContext.Provider {...props} value={value} />;
};

/**
 * Hook to be used in components that will allow us access to the values passed into the search provider above.
 */
export const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuthContext must be used within a AuthProvider');
    }
    return context;
};
